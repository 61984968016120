import api from '../api/index'

export const getCompanies = async (params: { nome: string }) => {
  const response = await api.get('/admin/fabrica', { params }).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const getSummary = async (params: object) => {
  const response = await api.get('/admin/assinatura/ver', { params: params }).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const getInvoice = async (params: any) => {

  const response = await api.put('/admin/assinatura/novoboleto', params).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const updateSummary = async (params: any) => {
  const response = await api.put('/admin/assinatura/marcapago', params).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const createSignature = async (params: {
  valor: number,
  formaPagamento: number,
  cobrado: boolean,
  concluido: boolean,
  dataVencimento: string,
  fabrica: any
}
) => {
  const response = await api.post('/admin/assinatura/add', params).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const updateSignature = async (params: {
  valor: number,
  formaPagamento: number,
  cobrado: boolean,
  concluido: boolean,
  dataVencimento: string,
  fabrica: any
}
) => {
  const response = await api.put('/admin/assinatura/atualiza', params).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const updatePlan = async (params: object) => {
  const response = await api.put('/admin/assinatura/plano', params).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const releaseAccess = async (params: object) => {
  const response = await api.put('/admin/fabrica/liberar', {fabrica: params}).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const updateDueDateOfSignature = async (dueDate: string, params: object) => {
  const response = await api.put(`/admin/assinatura/atualiza/${dueDate}`, params).then((response) => {
    return response.data
  }).catch((error) =>{
    ErrorUnknown(error)
  })

  return response
}

export const cancelSignature = async (params: object) => {
  const response = await api.put('/admin/assinatura/cancela', params).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response
}

export const cancelAccount = async (params: object) => {
  const response = await api.put('/admin/conta/cancela', params).then((response) => {
    return response.data
  }).catch((error) => {
    ErrorUnknown(error)
  })
  return response

}

const ErrorUnknown = (error: any) => {
  throw {
    response: {
      data: {
        message: error.message === 'Network Error' ?
          'Erro inesperado! Entre em contato com a equipe de desenvolvimento.'
          : error.response.data.message
      }
    }
  }
}