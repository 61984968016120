import * as React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { Dashboard } from '../pages/Dashboard/Dashboard'
import { Report } from '../pages/Report/Report'
import { Navbar } from '../components/navbar/navbar'
import { Footer } from '../components/footer/footer'
import { Signature } from '../pages/Signature/Signature'
import { SuperUser } from '../pages/SuperUser/SuperUser'
import { Contingency } from '../pages/Contingency/Contingency'
import { PageNotFound404 } from './PageNotFound404'
import { Login } from 'pages/Login/Login'

const App: React.FC<{ page: JSX.Element }> = ({ ...props }) => {
  const { page } = props
  const userLogged = localStorage.getItem('currentUser')
  if (!userLogged) {
    window.location.pathname = '/'
    return <><Login /></>
  }

  return (
    <>
      <Navbar />
      <div className="container-fluid" style={{ position: 'relative', minHeight: '91vh' }}>
        <div style={{ paddingBottom: '60px' }}>
          {page}
        </div>
        <Footer />
      </div>
    </>
  )
}
export const router = createBrowserRouter([
  { path: '*', element: <PageNotFound404 /> },
  { path: '/', element: <Login /> },
  { path: '/dashboard', element: <App page={<Dashboard />} /> },
  { path: '/relatorios', element: <App page={<Report />} /> },
  { path: '/assinaturas', element: <App page={<Signature />} /> },
  { path: '/super-usuario', element: <App page={<SuperUser />} /> },
  { path: '/contingencia', element: <App page={<Contingency />} /> }

])





