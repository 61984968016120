import { useEffect } from 'react'
import { releaseAccess } from '../../../../services/Signature/Signature'

export const ReleaseAccess: React.FC<{ companySelected: any, setReleaseAccess: any }> = ({ ...props }) => {
    const { companySelected, setReleaseAccess } = props
    useEffect(() => {
        const handleReleaseAccess = async () => {
            try {
                if (companySelected !== null) {
                    const response = await releaseAccess(companySelected)
                    alert(response.message)
                }
            } catch (error: any) {
                alert(error.response.data.message || 'Algum erro aconteceu!')
            } finally {
                setReleaseAccess(false)
            }
        }

        handleReleaseAccess()
    }, [companySelected, setReleaseAccess])

    return (
        <>
        </>
    )
}
